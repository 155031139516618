<template>
  <div>
    <Header>
      <template slot="title">资产明细</template>
    </Header>
    <div class="transaction">
      <div class="top">
        <div class="money">
          {{ parseFloat(total).toFixed(4) }}
        </div>
        <div class="txt">账户总资产(USD)</div>
      </div>
      <div class="menubtn">
        <div class="btntxt" @click="clickTopup">转入</div>
        <div class="btntxt" @click="clickwithdrawal">转出</div>
        <div class="btntxt" @click="clickexchange">
          平移
        </div>
      </div>
      <div class="celldata">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <!--v-if="item.coin_symbol == 'ATT'"-->
            <div
              class="cell"
              v-for="item in listdata"

              :key="item.id"
              @click="clickRecordList(item)"
            >
              <div style="display: flex;justify-content: space-between;">
                <div class="name"  v-if="item.coin_symbol=='POINT'">
                  CNY
                </div>
                <div class="name" v-else>
                  {{ item.coin_symbol }}
                </div>
                <div  v-if="item.coin_symbol=='ATT'"  @click.stop="toFanLog"
                      style="color: #27cc7f;font-size: 0.37333rem;font-weight: 700;font-family: 'PingFang SC';text-align: center;">
                  我的全返
                </div>
              </div>
              <div class="num">
                <div class="able">
                  <div class="txt">
                    {{ parseFloat(item.cloud_balance).toFixed(3) }}
                  </div>
                  <div class="tip">可用</div>
                </div>
                <div class="able" v-if="item.coin_symbol=='ATT'">
                  <div class="txt">
                    {{ parseFloat(item.shen_sell).toFixed(3) }}
                  </div>
                  <div class="tip">可售</div>
                </div>
                <div class="able" v-if="item.coin_symbol=='POINT'"   @click.stop="toDaiLog">
                  <div class="txt">
                    {{ parseFloat(item.dai_balance).toFixed(3) }}
                  </div>
                  <div class="tip">待返ATT</div>
                </div>
                <div class="able"  v-else-if="item.coin_symbol!='ATT'">
                  <div class="txt">
                    {{ parseFloat(item.freeze_balance).toFixed(3) }}
                  </div>
                  <div class="tip">冻结</div>
                </div>

              </div>
              <div class="num">

                <div class="able" v-if="item.coin_symbol=='ATT'">
                  <div class="txt">
                    {{ parseFloat(item.shen_ti).toFixed(3) }}
                  </div>
                  <div class="tip">可提(薄饼)</div>
                </div>
                <div class="able" v-if="item.coin_symbol=='ATT'">
                  <div class="txt">
                    {{ parseFloat(item.trade_ti).toFixed(3) }}
                  </div>
                  <div class="tip">可提(交易所)</div>
                </div>
                <!--<div class="able" ></div>-->
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { getWalletInfo } from "@/request/api";
import { cnyToAtt } from "@/request/shopapi";
import { Toast } from "vant";
export default {
  name: "CodeTransactions",
  components: {
    Header,
  },
  data() {
    return {
      total: 0,
      refreshing: false,
      loading: false,
      finished: false,
      address: "",
      listdata: [],
        // cnyToAtt:1,
    };
  },

  activated() {
    this.address = sessionStorage.getItem("address");
     /* cnyToAtt({}).then(res=> {
          console.log(res);
          this.cnyToAtt=res.data;
      })*/
  },

  methods: {
      toDaiLog(){
          this.$router.push({
              path: "/home/mine/daiLog",
              query: {

              },
          });
      },
      toFanLog(){
          this.$router.push({
              path: "/home/mine/fanLog",
              query: {

              },
          });
      },
    clickRecordList(item) {
      this.$router.push({
        path: "/home/mine/transactions/recordList",
        query: {
          coin: item.coin_symbol,
        },
      });
    },
    clickTopup() {
      // Toast("即将开放");
      // return false;
      this.$router.push({
        path: "/home/mine/transactions/topup",
      });
    },
    clickwithdrawal() {
      // Toast("即将开放");
      // return false;
      this.$router.push({
        path: "/home/mine/transactions/withdrawal",
      });
    },
    clickexchange() {
      this.$router.push({
        path: "/home/mine/transactions/exchange",
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.address) {
        this.getDataList();
        this.currentPage++;
      } else {
        this.loading = false;
      }
    },
    getDataList() {
      const params = {
        address: this.address,
      };
      getWalletInfo(params).then((res) => {
        if (this.refreshing) {
          this.listdata = [];
          this.refreshing = false;
        }
        this.total = res.data.total;
        this.listdata = res.data.list.reverse();
        this.finished = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction {
  padding: 32px;
  background: #0c1913;
  min-height: 100vh;
  .top {
    width: 686px;
    height: 168px;
    border-radius: 24px;
    opacity: 1;
    background: linear-gradient(
      135.4deg,
      rgba(76, 202, 129, 1) 0%,
      rgba(70, 183, 121, 1) 100%
    );
    padding: 32px 24px;
    .money {
      color: rgba(214, 255, 227, 1);
      font-size: 44px;
      font-weight: 500;
      font-family: "DIN";
    }
    .txt {
      margin-top: 8px;
      color: rgba(214, 255, 227, 1);
      font-size: 24px;
      font-weight: 400;
      font-family: "PingFang SC";
    }
  }
  .menubtn {
    display: flex;
    justify-content: space-around;
    margin-top: 46px;
    .btntxt {
      color: rgba(39, 204, 127, 1);
      font-size: 28px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
    }
  }
  .celldata {
    margin-top: 40px;
    .cell {
      width: 686px;
      min-height: 232px;
      border-radius: 24px;
      opacity: 1;
      background: rgba(32, 40, 35, 1);
      margin-bottom: 24px;
      padding: 24px;
      .name {
        color: rgba(214, 255, 227, 1);
        font-size: 32px;
        font-weight: 700;
        font-family: "PingFang SC";
      }
      .num {
        display: flex;
        justify-content: start;
        margin-top: 32px;
        .able {
          width: 50%;
          .txt {
            color: rgba(182, 217, 195, 1);
            font-weight: 500;
            font-size: 35px;
            font-family: "DIN";
          }
          .tip {
            color: rgba(139, 166, 149, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
          }
        }
      }
    }
  }
}
</style>